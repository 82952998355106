<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">截屏备案记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="projectCode"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div class="df" style="padding-right:15px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="机构名称" align="left" show-overflow-tooltip prop="compName" />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
              />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" />
              <el-table-column label="截屏次数" align="left" show-overflow-tooltip prop="screenshotTimes" />
              <el-table-column
                label="最近一次截取时间"
                align="left"
                show-overflow-tooltip
                prop="screenshotTime"
              >
                <template slot-scope="scope">{{ scope.row.screenshotTime | momentWu }}</template>
              </el-table-column> 
              <el-table-column label="操作" align="center" width="200px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 3px"
                    @click="lookupTrainCert(scope.row)"
                  >详情</el-button>
                  <el-button
                      type="text"
                      size="mini"
                      style="padding:0 3px"
                      @click="screenShot(scope.row)"
                  >截屏</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
      title="查看截屏备案表"
      :visible.sync="dialogScreenshot"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadCert"
    ><div id="pdf-Screen" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex"; 
import pdf from "pdfobject";
export default {
  name: "ScreenCaptureOrg",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      input:"",
      dialogScreenshot: false,
      projectCode:"",
      projectName: "",
      compId: "",
      CompanyList: [],
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems"
    })
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      this.doFetch({
        url: "/screenshot/org/page",
        params,
        pageNum
      });
    },
    screenShot(row) {
      const params = {
        pageNum: 1,
        pageSize: 0,
        projectId : row.projectId
      };
      this.$post("/gov/project/userStudyRecordExport", params).then(res => {
        if (res.status == 0) {
          this.dialogScreenshot = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-Screen");
          });
        } else {
          this.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    lookupTrainCert(row) {
      this.$router.push({
          path: "/web/ScreenCaptureInfo",
          query: {
            projectId: row.projectId,
            compId:row.compId,
            projectName:row.projectName
          }
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  }
};
</script>
<style lang="less">
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
